<template>
  <section class="main-section">
    <div class="main-layout" style="padding-top:0 !important;">
      <h3 class="h-top">Herbert Maier</h3>
      <p>玛娅家族以⽊艺师的身份曾服务于普鲁⼠皇室 。1966 年赫伯特·玛娅在德国南部⿊森林地区巴登 - 符腾堡州的巴林根建⽴了现代化的橱柜⽣产基地，⾃此开始了玛娅成功史并延续到现在的品牌——<b>MAIER KÜCHEN</b> 。</p>
      <p style="margin-top: 1rem;">作为在⾏业变⾰中创建的家族式企业，不落窠⾅、勇于创新的观点半个世纪以来始终在主导玛娅的思想和⾏为，并鞭策我们在传统⽊⼯制造和机械化⽣产的碰撞中不断地完善和超越⾃我。</p>
      <v-img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/home-1.png" style="margin-top: 1rem;"></v-img>
    </div>
    <v-row justify="end" class="bg-right">
      <v-col cols="6">
        <v-img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/bg-right.png"></v-img>
      </v-col>
    </v-row>
    <div class="main-layout">
      <h2 class="h-title">MAIER历程</h2>
      <v-img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/home-history.png" class="img-history"></v-img>
      <div class="click-link"><a href="/history">点击了解玛娅品牌历程</a></div>
    </div>
    <div class="main-layout">
      <h2 class="h-title h-title-space">MAIER空间</h2>
      <div v-for="s in space" :key="s.id">
        <h3 class="space-title-en">{{ s.title_en }}</h3>
        <h3 class="space-title">{{ s.title }} <span></span></h3>
        <v-img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/' + s.img" class="img-space"></v-img>
        <div class="click-link"><a :href="s.link">点击查看详情</a></div>
        <div :class="'space-content space-content-' + s.id" v-html="s.content"></div>
      </div>
    </div>
    <div class="main-layout-shop">
      <h2 class="h-title">MAIER展厅</h2>
      <template>
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide class="slide" v-for="(s, i) in shops" :key="i">
            <div class="shop-box">
              <a :href="'/shop/' + i">
                <v-img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/shop/' + i + '/main.png'" class="img-shop"></v-img>
                <div class="shop-title">{{ s.title }}</div>
              </a>
            </div>
          </swiper-slide>
        </swiper>
      </template>
    </div>
    <div class="main-layout">
      <h2 class="h-title h-title-service">MAIER服务</h2>
      <div class="d-flex justify-space-between">
        <div v-for="s in services" :key="s.id" class="service-card">
          <img class="img-service" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/icons/service_' + s.icon">
          <h3 class="service-title">{{ s.title }} <span></span></h3>
          <div class="service-text" v-html="s.text"></div>
          <div class="service-link"><a :href="s.link"></a></div>
      </div>
      </div>
    </div>
    <div class="main-layout">
      <h2 class="h-title">合作品牌</h2>
      <img class="img-partner" src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/partners.png" />
    </div>
  </section>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    shops: [],
    space: [
      {
        id: 1,
        title: '橱柜产品系列',
        title_en: 'Kitchen Collections',
        img: 'space-1.png',
        link: '/space-cabinet',
        content: '<p>玛娅橱柜产品迎合全球极简主义设计⻛潮。在冷艳与轻奢这两种即相似⼜⽭盾的⻛格中，产⽣出了<b>⼲净明亮且韵律⼗⾜的线条，注重使⽤功能和⽆可挑剔的对⽐感。</b></p>'
      },
      {
        id: 2,
        title: '衣帽产品系列',
        title_en: 'Wardrobe Collections',
        img: 'space-2.png',
        link: '/space-closet',
        content: '<p>⾐帽间是装扮⾃⼰提升⽓质的场所，玛娅为您量身定制专属与您的优雅、实⽤空间，让您的⾐帽间<b>在颜值与实⽤之间达成完美平衡。</b></p>'
      },
      {
        id: 3,
        title: '收纳产品系列',
        title_en: 'Other Cabinets',
        img: 'space-3.png',
        link: '/space-storage',
        content: '<p>玛娅将房屋内六⼤空间内的各种家居收纳系统纳⼊产品体系，<b>为客户提供专业化的定制解决方案。</b></p>'
      }
    ],
    services: [
      {
        icon: 'ys.png',
        title: '品牌优势',
        text: '<p>八大优势</p><p>强势赋能</p>',
        link: '/advantage'
      },
      {
        icon: 'by.png',
        title: '清洁保养',
        text: '<p>精准模块</p><p>专业指导</p>',
        link: '/maintenance'
      },
      {
        icon: 'zs.png',
        title: '招商加盟',
        text: '<p>专业的团队</p><p>创新设计</p>',
        link: '/join'
      }
    ],
    pageId: 0,
    swiperOption: {
      initialSlide: 0,
      loop: false,
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: '4.6875%',
      pagination: {
        el: '.swiper-pagination'
      }
    }
  }),
  created () {
    this.$store.commit('updateActiveNav', 0)
    this.shops = this.$shops
  },
  mounted: function () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.$nextTick(function () {
          this.info.banners = val.banner[this.pageId]
        })
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/home.css';
</style>
